import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  CssBaseline,
  AppBar,
  Typography,
} from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import {
  fetchData,
  saveOrUpdateData,
  saveVerifyingData1,
  saveComment,
  saveCheckbox,
  getSADAndDrugaWartosc,
  updatePrzygotowaneZweryfikowane,
  updateZweryfikowanePrzygotowaneDoWyslania,
  updateStatusDoPoprawy,
  updateStatusDoWyslania,
  updateStatusWstepnie,
  fetchUserPrivileges,
  saveInternalControlDataDates,
} from "../UniwersalListImport/functions";

import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  greyRow: {
    backgroundColor: "#dedede !important", // Ensure it overrides default styles
  },
  checkboxesHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    border: "1px solid #ddd",
    borderRadius: theme.spacing(1),
    overflowX: "auto",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
  },
  tableHeadCell: {
    fontWeight: "bold",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    padding: theme.spacing(1.5),
    borderBottom: "2px solid #ddd",
  },
  tableSubHeadCell: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    padding: theme.spacing(1),
    borderBottom: "1px solid #ddd",
  },
  checkCell: {
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(1),
    wordBreak: "break-word",
  },
  inputField: {
    width: "100%",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#eaeaea",
    },
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const UniwersalListImport = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    nrRef,
    osobaPrzygotowujaca,
    osobaWeryfikujaca,
    osobaWysylajaca,
    status,
    rodzajOdprawy,
  } = location.state || {};
  const [privileges, setPrivileges] = useState(null);
  const [wstepniePrzygotowane, setWstepniePrzygotowane] = useState(undefined);
  const [przygotowane, setPrzygotowane] = useState(undefined);
  const { id } = useParams();
  const token = localStorage.getItem("token");
  let username;

  if (token) {
    const decoded = jwtDecode(token);
    username = decoded.username;
  }

  const [data, setData] = useState({
    creationDate: "",
    lastUpdateDate: "",
    osoba_kotrolujaca: "",
    data_kontroli: "",
    textFields: {
      21: { value: "", verifyingPerson: "" },
      22: { value: "", verifyingPerson: "" },
      26: { value: "", verifyingPerson: "" },
    },
    selectFields: {
      13: { value: "", verifyingPerson: "" },
      19: { value: "", verifyingPerson: "" },
      24: { value: "", verifyingPerson: "" },
      27: { value: "", verifyingPerson: "" },
      32: { value: "", verifyingPerson: "" },
      33: { value: "", verifyingPerson: "" },
    },
    checkboxesPreparation: {
      1: { verifying: false, verifyingPerson: "" },
      2: { verifying: false, verifyingPerson: "" },
      3: { verifying: false, verifyingPerson: "" },
      4: { verifying: false, verifyingPerson: "" },
      5: { verifying: false, verifyingPerson: "" },
      6: { verifying: false, verifyingPerson: "" },
      7: { verifying: false, verifyingPerson: "" },
      8: { verifying: false, verifyingPerson: "" },
      9: { verifying: false, verifyingPerson: "" },
      10: { verifying: false, verifyingPerson: "" },
      11: { verifying: false, verifyingPerson: "" },
      12: { verifying: false, verifyingPerson: "" },
      14: { verifying: false, verifyingPerson: "" },
      15: { verifying: false, verifyingPerson: "" },
      16: { verifying: false, verifyingPerson: "" },
      17: { verifying: false, verifyingPerson: "" },
      18: { verifying: false, verifyingPerson: "" },
      20: { verifying: false, verifyingPerson: "" },
      23: { verifying: false, verifyingPerson: "" },
      25: { verifying: false, verifyingPerson: "" },
      28: { verifying: false, verifyingPerson: "" },
      29: { verifying: false, verifyingPerson: "" },
      30: { verifying: false, verifyingPerson: "" },
      31: { verifying: false, verifyingPerson: "" },
    },
    checkboxesVerification: {
      1: { good: false, bad: false },
      2: { good: false, bad: false },
      3: { good: false, bad: false },
      4: { good: false, bad: false },
      5: { good: false, bad: false },
      6: { good: false, bad: false },
      7: { good: false, bad: false },
      8: { good: false, bad: false },
      9: { good: false, bad: false },
      10: { good: false, bad: false },
      11: { good: false, bad: false },
      12: { good: false, bad: false },
      13: { good: false, bad: false },
      14: { good: false, bad: false },
      15: { good: false, bad: false },
      16: { good: false, bad: false },
      17: { good: false, bad: false },
      18: { good: false, bad: false },
      19: { good: false, bad: false },
      20: { good: false, bad: false },
      21: { good: false, bad: false },
      22: { good: false, bad: false },
      23: { good: false, bad: false },
      24: { good: false, bad: false },
      25: { good: false, bad: false },
      26: { good: false, bad: false },
      27: { good: false, bad: false },
      28: { good: false, bad: false },
      29: { good: false, bad: false },
      30: { good: false, bad: false },
      31: { good: false, bad: false },
      32: { good: false, bad: false },
      33: { good: false, bad: false },
    },
    checkboxesInternalControl: {
      1: { good: false, bad: false },
      2: { good: false, bad: false },
      3: { good: false, bad: false },
      4: { good: false, bad: false },
      5: { good: false, bad: false },
      6: { good: false, bad: false },
      7: { good: false, bad: false },
      8: { good: false, bad: false },
      9: { good: false, bad: false },
      10: { good: false, bad: false },
      11: { good: false, bad: false },
      12: { good: false, bad: false },
      13: { good: false, bad: false },
      14: { good: false, bad: false },
      15: { good: false, bad: false },
      16: { good: false, bad: false },
      17: { good: false, bad: false },
      18: { good: false, bad: false },
      19: { good: false, bad: false },
      20: { good: false, bad: false },
      21: { good: false, bad: false },
      22: { good: false, bad: false },
      23: { good: false, bad: false },
      24: { good: false, bad: false },
      25: { good: false, bad: false },
      26: { good: false, bad: false },
      27: { good: false, bad: false },
      28: { good: false, bad: false },
      29: { good: false, bad: false },
      30: { good: false, bad: false },
      31: { good: false, bad: false },
      32: { good: false, bad: false },
      33: { good: false, bad: false },
    },
    nrRef: "",
    osobaPrzygotowujaca: "",
    osobaWeryfikujaca: "",
    comment: "",
  });

  const [loading, setLoading] = useState(true);
  const [isAllInternalControleSaved, setIsAllInternalControleSaved] =
    useState(false);
  const [allVeryfyingDataFilled, setAllVeryfyingDataFilled] = useState(false);
  const [allVerificationSet, setAllVerificationSet] = useState(false); // Nowy stan dla śledzenia, czy wszystkie checkboxy w kolumnie "Osoba weryfikująca" są ustawione
  const selectOptions = {
    13: ["TAK", "NIE"],
    19: ["POPRAWNY", "NIE DOTYCZY"],
    24: [
      "CFR",
      "CIF",
      "CIP",
      "CPT",
      "DAP",
      "DDP",
      "DPU",
      "EXW",
      "FAS",
      "FCA",
      "FOB",
    ],
    27: ["Do uznania", "Nie do uznania", "Nie dotyczy"],
    32: ["TAK", "NIE"],
    33: ["PRZELEW", "ODROCZENIE", "ODROCZENIE + GRN", "GRN", "ZWOLNIENIE"],
  };

  const loadUserPrivileges = async () => {
    const userPrivileges = await fetchUserPrivileges(username);
    setPrivileges(userPrivileges);
  };

  const columnMapping = {
    "Kontrola wewnętrzna": "internalControl",
    Import: "import",
    Eksport: "export",
    "Wyprowadzenie z magazynu": "warehouseOut",
    "Wprowadzenie na magazyn": "warehouseIn",
    "Uszlachetnianie czynne": "activeRefinement",
    "Uszlachetnianie bierne": "passiveRefinement",
    "Powrotny przywóz": "returnImport",
    "Powrotny wywóz": "returnExport",
    "Import - wpis do rejestru": "importRegisterEntry",
    "Eksport - wpis do rejestru": "exportRegisterEntry",
    "Eksport PL + import": "exportImport",
  };

  const checkPrivilege = (userPrivileges, rodzajOdprawy) => {
    if (!userPrivileges) return false;
    const privilegeKey = columnMapping[rodzajOdprawy];
    return privilegeKey ? userPrivileges[privilegeKey] === 1 : false;
  };

  const areAllRequiredFieldsFilled = (
    data,
    requiredTextFields,
    requiredSelectFields,
    requiredCheckboxes
  ) => {
    const allTextFieldsFilled = requiredTextFields.every(
      (fieldId) => data.textFields[fieldId]?.value
    );
    const allSelectFieldsFilled = requiredSelectFields.every(
      (fieldId) => data.selectFields[fieldId]?.value
    );
    const allCheckboxesChecked = requiredCheckboxes.every(
      (fieldId) => data.checkboxesPreparation[fieldId]?.verifying
    );
    return allTextFieldsFilled && allSelectFieldsFilled && allCheckboxesChecked;
  };
  const verifyAndSubmit = async (data, status, id, username) => {
    if (status !== "Do przygotowania" && status !== "Wstępnie przygotowane") {
      return;
    }

    const requiredTextFields = [21, 22, 26];
    const requiredSelectFields = [13, 19, 24, 27, 32, 33];
    const requiredCheckboxes = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 20, 23, 25, 28,
      29, 30, 31,
    ];

    const allRequiredFieldsFilled = areAllRequiredFieldsFilled(
      data,
      requiredTextFields,
      requiredSelectFields,
      requiredCheckboxes
    );

    try {
      const { SAD, Plik } = await getSADAndDrugaWartosc(id);
      const SADandPlikPresent = SAD && Plik;

      if (SADandPlikPresent && allRequiredFieldsFilled) {
        const hasPrivilege = checkPrivilege(privileges, rodzajOdprawy); // Używamy checkPrivilege
        const newStatus = hasPrivilege ? "Do wysłania" : "Do weryfikacji";

        const payload = {
          status: newStatus,
          Przygotowane: 1,
          Zweryfikowane: hasPrivilege ? 1 : 0,
          wstepniePrzygotowane: 1,
        };
        await updateZweryfikowanePrzygotowaneDoWyslania(id, payload, "przywoz");
        alert("Status zmieniony poprawnie."); // Pokaż wiadomość użytkownikowi
        navigate(
          `/detail/${encodeURIComponent(nrRef)}/${encodeURIComponent(id)}`
        );
      } else if (allRequiredFieldsFilled) {
        await updatePrzygotowaneZweryfikowane(
          id,
          { Przygotowane: 1, wstepniePrzygotowane: 1 },
          "przywoz"
        );
        alert("Status zmieniony poprawnie."); // Pokaż wiadomość użytkownikowi
        navigate(
          `/detail/${encodeURIComponent(nrRef)}/${encodeURIComponent(id)}`
        );
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const allDataSave = (data) => {
    const requiredSelectFields = [13];
    const requiredCheckboxes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14];
    const allSelectFieldsFilled = requiredSelectFields.every(
      (fieldId) => data.selectFields[fieldId]?.value
    );

    const allCheckboxesChecked = requiredCheckboxes.every(
      (fieldId) => data.checkboxesPreparation[fieldId]?.verifying
    );
    return allSelectFieldsFilled && allCheckboxesChecked;
  };

  const areSpecificFieldsDisabled = allDataSave(data);

  const allInternalControlSave = (data) => {
    const requiredCheckboxes = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
    ];

    const allCheckboxesHaveValue = requiredCheckboxes.every(
      (fieldId) =>
        data.checkboxesInternalControl[fieldId] &&
        (data.checkboxesInternalControl[fieldId].good === true ||
          data.checkboxesInternalControl[fieldId].bad === true)
    );

    return allCheckboxesHaveValue;
  };

  const allVeryfyingDataSave = (data) => {
    const requiredCheckboxes = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
    ];

    const allCheckboxesHaveValue = requiredCheckboxes.every(
      (fieldId) =>
        data.checkboxesVerification[fieldId] &&
        (data.checkboxesVerification[fieldId].good === true ||
          data.checkboxesVerification[fieldId].bad === true)
    );

    return allCheckboxesHaveValue;
  };

  const checkIfAllVerificationSet = (data) => {
    return Object.keys(data.checkboxesVerification).every(
      (key) =>
        data.checkboxesVerification[key].good !== false ||
        data.checkboxesVerification[key].bad !== false
    );
  };

  const fetchAndSetData = async () => {
    try {
      setLoading(true);
      const fetchedData = await fetchData(id);
      loadUserPrivileges();

      setWstepniePrzygotowane(fetchedData.wstepniePrzygotowane || 0);
      setPrzygotowane(fetchedData.Przygotowane || 0);

      const mappedData = {
        textFields: {
          21: {
            value: fetchedData.field_21 || "",
            verifyingPerson: fetchedData.verifyingPerson_21 || "",
          },
          22: {
            value: fetchedData.field_22 || "",
            verifyingPerson: fetchedData.verifyingPerson_22 || "",
          },
          26: {
            value: fetchedData.field_26 || "",
            verifyingPerson: fetchedData.verifyingPerson_26 || "",
          },
        },
        selectFields: {
          13: {
            value: fetchedData.field_13 || "",
            verifyingPerson: fetchedData.verifyingPerson_13 || "",
          },
          19: {
            value: fetchedData.field_19 || "",
            verifyingPerson: fetchedData.verifyingPerson_19 || "",
          },
          24: {
            value: fetchedData.field_24 || "",
            verifyingPerson: fetchedData.verifyingPerson_24 || "",
          },
          27: {
            value: fetchedData.field_27 || "",
            verifyingPerson: fetchedData.verifyingPerson_27 || "",
          },
          32: {
            value: fetchedData.field_32 || "",
            verifyingPerson: fetchedData.verifyingPerson_32 || "",
          },
          33: {
            value: fetchedData.field_33 || "",
            verifyingPerson: fetchedData.verifyingPerson_33 || "",
          },
        },
        checkboxesPreparation: {
          1: {
            verifying: Boolean(fetchedData.field_1),
            verifyingPerson: fetchedData.verifyingPerson_1 || "",
          },
          2: {
            verifying: Boolean(fetchedData.field_2),
            verifyingPerson: fetchedData.verifyingPerson_2 || "",
          },
          3: {
            verifying: Boolean(fetchedData.field_3),
            verifyingPerson: fetchedData.verifyingPerson_3 || "",
          },
          4: {
            verifying: Boolean(fetchedData.field_4),
            verifyingPerson: fetchedData.verifyingPerson_4 || "",
          },
          5: {
            verifying: Boolean(fetchedData.field_5),
            verifyingPerson: fetchedData.verifyingPerson_5 || "",
          },
          6: {
            verifying: Boolean(fetchedData.field_6),
            verifyingPerson: fetchedData.verifyingPerson_6 || "",
          },
          7: {
            verifying: Boolean(fetchedData.field_7),
            verifyingPerson: fetchedData.verifyingPerson_7 || "",
          },
          8: {
            verifying: Boolean(fetchedData.field_8),
            verifyingPerson: fetchedData.verifyingPerson_8 || "",
          },
          9: {
            verifying: Boolean(fetchedData.field_9),
            verifyingPerson: fetchedData.verifyingPerson_9 || "",
          },
          10: {
            verifying: Boolean(fetchedData.field_10),
            verifyingPerson: fetchedData.verifyingPerson_10 || "",
          },
          11: {
            verifying: Boolean(fetchedData.field_11),
            verifyingPerson: fetchedData.verifyingPerson_11 || "",
          },
          12: {
            verifying: Boolean(fetchedData.field_12),
            verifyingPerson: fetchedData.verifyingPerson_12 || "",
          },
          14: {
            verifying: Boolean(fetchedData.field_14),
            verifyingPerson: fetchedData.verifyingPerson_14 || "",
          },
          15: {
            verifying: Boolean(fetchedData.field_15),
            verifyingPerson: fetchedData.verifyingPerson_15 || "",
          },
          16: {
            verifying: Boolean(fetchedData.field_16),
            verifyingPerson: fetchedData.verifyingPerson_16 || "",
          },
          17: {
            verifying: Boolean(fetchedData.field_17),
            verifyingPerson: fetchedData.verifyingPerson_17 || "",
          },
          18: {
            verifying: Boolean(fetchedData.field_18),
            verifyingPerson: fetchedData.verifyingPerson_18 || "",
          },
          20: {
            verifying: Boolean(fetchedData.field_20),
            verifyingPerson: fetchedData.verifyingPerson_20 || "",
          },
          23: {
            verifying: Boolean(fetchedData.field_23),
            verifyingPerson: fetchedData.verifyingPerson_23 || "",
          },
          25: {
            verifying: Boolean(fetchedData.field_25),
            verifyingPerson: fetchedData.verifyingPerson_25 || "",
          },
          28: {
            verifying: Boolean(fetchedData.field_28),
            verifyingPerson: fetchedData.verifyingPerson_28 || "",
          },
          29: {
            verifying: Boolean(fetchedData.field_29),
            verifyingPerson: fetchedData.verifyingPerson_29 || "",
          },
          30: {
            verifying: Boolean(fetchedData.field_30),
            verifyingPerson: fetchedData.verifyingPerson_30 || "",
          },
          31: {
            verifying: Boolean(fetchedData.field_31),
            verifyingPerson: fetchedData.verifyingPerson_31 || "",
          },
        },
        checkboxesVerification: {
          1: {
            good: fetchedData.weryfikacja_1 === 1,
            bad: fetchedData.weryfikacja_1 === 0,
          },
          2: {
            good: fetchedData.weryfikacja_2 === 1,
            bad: fetchedData.weryfikacja_2 === 0,
          },
          3: {
            good: fetchedData.weryfikacja_3 === 1,
            bad: fetchedData.weryfikacja_3 === 0,
          },
          4: {
            good: fetchedData.weryfikacja_4 === 1,
            bad: fetchedData.weryfikacja_4 === 0,
          },
          5: {
            good: fetchedData.weryfikacja_5 === 1,
            bad: fetchedData.weryfikacja_5 === 0,
          },
          6: {
            good: fetchedData.weryfikacja_6 === 1,
            bad: fetchedData.weryfikacja_6 === 0,
          },
          7: {
            good: fetchedData.weryfikacja_7 === 1,
            bad: fetchedData.weryfikacja_7 === 0,
          },
          8: {
            good: fetchedData.weryfikacja_8 === 1,
            bad: fetchedData.weryfikacja_8 === 0,
          },
          9: {
            good: fetchedData.weryfikacja_9 === 1,
            bad: fetchedData.weryfikacja_9 === 0,
          },
          10: {
            good: fetchedData.weryfikacja_10 === 1,
            bad: fetchedData.weryfikacja_10 === 0,
          },
          11: {
            good: fetchedData.weryfikacja_11 === 1,
            bad: fetchedData.weryfikacja_11 === 0,
          },
          12: {
            good: fetchedData.weryfikacja_12 === 1,
            bad: fetchedData.weryfikacja_12 === 0,
          },
          13: {
            good: fetchedData.weryfikacja_13 === 1,
            bad: fetchedData.weryfikacja_13 === 0,
          },
          14: {
            good: fetchedData.weryfikacja_14 === 1,
            bad: fetchedData.weryfikacja_14 === 0,
          },
          15: {
            good: fetchedData.weryfikacja_15 === 1,
            bad: fetchedData.weryfikacja_15 === 0,
          },
          16: {
            good: fetchedData.weryfikacja_16 === 1,
            bad: fetchedData.weryfikacja_16 === 0,
          },
          17: {
            good: fetchedData.weryfikacja_17 === 1,
            bad: fetchedData.weryfikacja_17 === 0,
          },
          18: {
            good: fetchedData.weryfikacja_18 === 1,
            bad: fetchedData.weryfikacja_18 === 0,
          },
          19: {
            good: fetchedData.weryfikacja_19 === 1,
            bad: fetchedData.weryfikacja_19 === 0,
          },
          20: {
            good: fetchedData.weryfikacja_20 === 1,
            bad: fetchedData.weryfikacja_20 === 0,
          },
          21: {
            good: fetchedData.weryfikacja_21 === 1,
            bad: fetchedData.weryfikacja_21 === 0,
          },
          22: {
            good: fetchedData.weryfikacja_22 === 1,
            bad: fetchedData.weryfikacja_22 === 0,
          },
          23: {
            good: fetchedData.weryfikacja_23 === 1,
            bad: fetchedData.weryfikacja_23 === 0,
          },
          24: {
            good: fetchedData.weryfikacja_24 === 1,
            bad: fetchedData.weryfikacja_24 === 0,
          },
          25: {
            good: fetchedData.weryfikacja_25 === 1,
            bad: fetchedData.weryfikacja_25 === 0,
          },
          26: {
            good: fetchedData.weryfikacja_26 === 1,
            bad: fetchedData.weryfikacja_26 === 0,
          },
          27: {
            good: fetchedData.weryfikacja_27 === 1,
            bad: fetchedData.weryfikacja_27 === 0,
          },
          28: {
            good: fetchedData.weryfikacja_28 === 1,
            bad: fetchedData.weryfikacja_28 === 0,
          },
          29: {
            good: fetchedData.weryfikacja_29 === 1,
            bad: fetchedData.weryfikacja_29 === 0,
          },
          30: {
            good: fetchedData.weryfikacja_30 === 1,
            bad: fetchedData.weryfikacja_30 === 0,
          },
          31: {
            good: fetchedData.weryfikacja_31 === 1,
            bad: fetchedData.weryfikacja_31 === 0,
          },
          32: {
            good: fetchedData.weryfikacja_32 === 1,
            bad: fetchedData.weryfikacja_32 === 0,
          },
          33: {
            good: fetchedData.weryfikacja_33 === 1,
            bad: fetchedData.weryfikacja_33 === 0,
          },
        },
        checkboxesInternalControl: {
          1: {
            good: fetchedData.internal_control_1 === 1,
            bad: fetchedData.internal_control_1 === 0,
          },
          2: {
            good: fetchedData.internal_control_2 === 1,
            bad: fetchedData.internal_control_2 === 0,
          },
          3: {
            good: fetchedData.internal_control_3 === 1,
            bad: fetchedData.internal_control_3 === 0,
          },
          4: {
            good: fetchedData.internal_control_4 === 1,
            bad: fetchedData.internal_control_4 === 0,
          },
          5: {
            good: fetchedData.internal_control_5 === 1,
            bad: fetchedData.internal_control_5 === 0,
          },
          6: {
            good: fetchedData.internal_control_6 === 1,
            bad: fetchedData.internal_control_6 === 0,
          },
          7: {
            good: fetchedData.internal_control_7 === 1,
            bad: fetchedData.internal_control_7 === 0,
          },
          8: {
            good: fetchedData.internal_control_8 === 1,
            bad: fetchedData.internal_control_8 === 0,
          },
          9: {
            good: fetchedData.internal_control_9 === 1,
            bad: fetchedData.internal_control_9 === 0,
          },
          10: {
            good: fetchedData.internal_control_10 === 1,
            bad: fetchedData.internal_control_10 === 0,
          },
          11: {
            good: fetchedData.internal_control_11 === 1,
            bad: fetchedData.internal_control_11 === 0,
          },
          12: {
            good: fetchedData.internal_control_12 === 1,
            bad: fetchedData.internal_control_12 === 0,
          },
          13: {
            good: fetchedData.internal_control_13 === 1,
            bad: fetchedData.internal_control_13 === 0,
          },
          14: {
            good: fetchedData.internal_control_14 === 1,
            bad: fetchedData.internal_control_14 === 0,
          },
          15: {
            good: fetchedData.internal_control_15 === 1,
            bad: fetchedData.internal_control_15 === 0,
          },
          16: {
            good: fetchedData.internal_control_16 === 1,
            bad: fetchedData.internal_control_16 === 0,
          },
          17: {
            good: fetchedData.internal_control_17 === 1,
            bad: fetchedData.internal_control_17 === 0,
          },
          18: {
            good: fetchedData.internal_control_18 === 1,
            bad: fetchedData.internal_control_18 === 0,
          },
          19: {
            good: fetchedData.internal_control_19 === 1,
            bad: fetchedData.internal_control_19 === 0,
          },
          20: {
            good: fetchedData.internal_control_20 === 1,
            bad: fetchedData.internal_control_20 === 0,
          },
          21: {
            good: fetchedData.internal_control_21 === 1,
            bad: fetchedData.internal_control_21 === 0,
          },
          22: {
            good: fetchedData.internal_control_22 === 1,
            bad: fetchedData.internal_control_22 === 0,
          },
          23: {
            good: fetchedData.internal_control_23 === 1,
            bad: fetchedData.internal_control_23 === 0,
          },
          24: {
            good: fetchedData.internal_control_24 === 1,
            bad: fetchedData.internal_control_24 === 0,
          },
          25: {
            good: fetchedData.internal_control_25 === 1,
            bad: fetchedData.internal_control_25 === 0,
          },
          26: {
            good: fetchedData.internal_control_26 === 1,
            bad: fetchedData.internal_control_26 === 0,
          },
          27: {
            good: fetchedData.internal_control_27 === 1,
            bad: fetchedData.internal_control_27 === 0,
          },
          28: {
            good: fetchedData.internal_control_28 === 1,
            bad: fetchedData.internal_control_28 === 0,
          },
          29: {
            good: fetchedData.internal_control_29 === 1,
            bad: fetchedData.internal_control_29 === 0,
          },
          30: {
            good: fetchedData.internal_control_30 === 1,
            bad: fetchedData.internal_control_30 === 0,
          },
          31: {
            good: fetchedData.internal_control_31 === 1,
            bad: fetchedData.internal_control_31 === 0,
          },
          32: {
            good: fetchedData.internal_control_32 === 1,
            bad: fetchedData.internal_control_32 === 0,
          },
          33: {
            good: fetchedData.internal_control_33 === 1,
            bad: fetchedData.internal_control_33 === 0,
          },
        },

        nrRef: fetchedData.custom_Nr_Ref || "",
        osobaPrzygotowujaca: fetchedData.OsobaPrzygotowujacaOdprawe || "",
        osobaWeryfikujaca: fetchedData.OsobaWeryfikujacaOdprawe || "",
        comment: fetchedData.comment || "",
        creationDate: fetchedData.created_at || "",
        lastUpdateDate: fetchedData.updated_at || "",
        osoba_kotrolujaca: fetchedData.osoba_kontrolujaca || "",
        data_kontroli: fetchedData.data_kontroli || "",
      };

      setData(mappedData);
      setIsAllInternalControleSaved(allInternalControlSave(mappedData));
      setAllVeryfyingDataFilled(allVeryfyingDataSave(mappedData));
      setAllVerificationSet(checkIfAllVerificationSet(mappedData));
      verifyAndSubmit(mappedData, status, id, username);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn("Brak rekordu w bazie. Tworzenie domyślnych danych...");

        const defaultData = {
          textFields: {
            21: { value: "", verifyingPerson: "" },
            22: { value: "", verifyingPerson: "" },
            26: { value: "", verifyingPerson: "" },
          },
          selectFields: {
            13: { value: "", verifyingPerson: "" },
            19: { value: "", verifyingPerson: "" },
            24: { value: "", verifyingPerson: "" },
            27: { value: "", verifyingPerson: "" },
            32: { value: "", verifyingPerson: "" },
            33: { value: "", verifyingPerson: "" },
          },
          checkboxesPreparation: {
            1: {
              verifying: "",
              verifyingPerson: "",
            },
            2: {
              verifying: "",
              verifyingPerson: "",
            },
            3: {
              verifying: "",
              verifyingPerson: "",
            },
            4: {
              verifying: "",
              verifyingPerson: "",
            },
            5: {
              verifying: "",
              verifyingPerson: "",
            },
            6: {
              verifying: "",
              verifyingPerson: "",
            },
            7: {
              verifying: "",
              verifyingPerson: "",
            },
            8: {
              verifying: "",
              verifyingPerson: "",
            },
            9: {
              verifying: "",
              verifyingPerson: "",
            },
            10: {
              verifying: "",
              verifyingPerson: "",
            },
            11: {
              verifying: "",
              verifyingPerson: "",
            },
            12: {
              verifying: "",
              verifyingPerson: "",
            },
            14: {
              verifying: "",
              verifyingPerson: "",
            },
            15: {
              verifying: "",
              verifyingPerson: "",
            },
            16: {
              verifying: "",
              verifyingPerson: "",
            },
            17: {
              verifying: "",
              verifyingPerson: "",
            },
            18: {
              verifying: "",
              verifyingPerson: "",
            },
            20: {
              verifying: "",
              verifyingPerson: "",
            },
            23: {
              verifying: "",
              verifyingPerson: "",
            },
            25: {
              verifying: "",
              verifyingPerson: "",
            },
            28: {
              verifying: "",
              verifyingPerson: "",
            },
            29: {
              verifying: "",
              verifyingPerson: "",
            },
            30: {
              verifying: "",
              verifyingPerson: "",
            },
            31: {
              verifying: "",
              verifyingPerson: "",
            },
          },
          checkboxesVerification: Object.fromEntries(
            Array.from({ length: 33 }, (_, i) => [
              i + 1,
              { good: false, bad: false },
            ])
          ),
          checkboxesInternalControl: Object.fromEntries(
            Array.from({ length: 33 }, (_, i) => [
              i + 1,
              { good: false, bad: false },
            ])
          ),
          nrRef: "",
          osobaPrzygotowujaca: "",
          osobaWeryfikujaca: "",
          comment: "",
          creationDate: "",
          lastUpdateDate: "",
          osoba_kotrolujaca: "",
          data_kontroli: "",
        };

        setData(defaultData);
        setWstepniePrzygotowane(0);
        setIsAllInternalControleSaved(false);
        setAllVeryfyingDataFilled(false);
        setAllVerificationSet(false);
        //setLoading(false);
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status]);

  const formatDate = (dateTimeString) => {
    const originalDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSZ");

    return originalDateTime.format("DD-MM-YYYY, HH:mm");
  };

  const handleInputChange = (id, field, value) => {
    setData((prevData) => {
      const newData = {
        ...prevData,
        [field]: {
          ...prevData[field],
          [id]: {
            value: value,
            verifyingPerson: username, // Ustawiamy osobę wprowadzającą
          },
        },
      };
      return newData;
    });
  };

  const handleVerificationChange = (id, type) => {
    setData((prevData) => {
      const currentState = prevData.checkboxesVerification[id] || {
        good: false,
        bad: false,
        verifyingPerson: null,
      };

      const newVerification = {
        good: false,
        bad: false,
        verifyingPerson: currentState.verifyingPerson,
      };

      // Jeśli kliknięty checkbox jest już zaznaczony, odznacz go
      if (currentState[type]) {
        newVerification.verifyingPerson = null; // Usuwamy osobę wprowadzającą, jeśli odznaczono
      } else {
        // W przeciwnym razie zaznacz ten checkbox i ustaw osobę wprowadzającą
        newVerification[type] = true;
        newVerification.verifyingPerson = username;
      }

      const newData = {
        ...prevData,
        checkboxesVerification: {
          ...prevData.checkboxesVerification,
          [id]: newVerification,
        },
      };

      return newData;
    });
  };

  const handleInternalControlChange = (id, type) => {
    setData((prevData) => {
      const currentState = prevData.checkboxesInternalControl[id] || {
        good: false,
        bad: false,
      };
      const newVerification = {
        good: currentState.good,
        bad: currentState.bad,
      };

      // Jeśli kliknięty checkbox jest już zaznaczony, odznacz go
      if (currentState[type]) {
        newVerification[type] = false;
      } else {
        // W przeciwnym razie zaznacz ten checkbox i odznacz drugi
        newVerification.good = false;
        newVerification.bad = false;
        newVerification[type] = true;
      }

      const newData = {
        ...prevData,
        checkboxesInternalControl: {
          ...prevData.checkboxesInternalControl,
          [id]: newVerification,
        },
      };

      return newData;
    });
  };

  const handleCommentChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      comment: e.target.value,
    }));
  };

  const handleCommentChangeInternalControl = (e) => {
    setData((prevData) => ({
      ...prevData,
      comment: e.target.value,
    }));
  };

  const rows = [
    {
      id: 1,
      check: "1. Korespondencja umieszczona w folderze wskazanym w systemie",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 2,
      check: "2. Kontrahent otrzymał ticket",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 3,
      check: "3. Przygotowano plik do zaczytania do HUZAR",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 4,
      check: "4. Poprawny numer referencyjny z systemu wpisany w SAD",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 5,
      check: "5. Dane nadawcy",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 6,
      check: "6. Dane odbiorcy",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 7,
      check: "7. Kod kraju wysyłki",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 8,
      check: "8. Waluta i kwota na fakturze",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 9,
      check: "9. Rodzaj transakcji",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 10,
      check: "10. Opis towaru i tłumaczenie",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 11,
      check: "11. Czy zastosowano właściwą procedurę celną",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 12,
      check: "12. Kraj pochodzenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 13,
      check:
        "13. Wybierz czy kraje transakcji objęte są embargiem lub sankcjami",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 14,
      check: "14. Taryfikacja towaru",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 15,
      check: "15. Numer i typ faktury",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 16,
      check: "16. Masa brutto",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 17,
      check: "17. Masa netto",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 18,
      check: "18. Rodzaj i liczba opakowań",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 19,
      check: "19. Numer świadectwa",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 20,
      check: "20. Rodzaj przedstawicielstwa",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 21,
      check: "21. Wpisz urząd wprowadzenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 22,
      check: "22. Wpisz numery środka transportu",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 23,
      check: "23. Kraj przynależności środka transportu",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 24,
      check: "24. Wybierz INCOTERMS",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 25,
      check: "25. Koszty transportu",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 26,
      check: "26. Wpisz lokalizację towaru",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 27,
      check: "27. Preferencje",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 28,
      check: "28. Numer listu przewozowego",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 29,
      check: "29. Stawka cła",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 30,
      check: "30. Stawka VAT",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 31,
      check:
        "31. Metoda płatności [użycie zabezpieczenia wymaga zgody CM lub DR poza podmiotem MAN]",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 32,
      check:
        "32. Wybierz czy wymagane jest zabezpieczenie na procedurę czasowego składowania",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
    {
      id: 33,
      check: "33. Wybierz należności-celno podatkowe / rodzaj zabezpieczenia",
      verifying: false,
      verifyingPerson: false,
      internalControl: false,
    },
  ];

  const updateStatusToPrePrepared = async () => {
    if (
      areSpecificFieldsDisabled &&
      status === "Do przygotowania" &&
      wstepniePrzygotowane !== 1
    ) {
      try {
        const payload = {
          status: "Wstępnie przygotowane", // Nowy status
          wstepniePrzygotowane: 1, // Zmiana wartości w bazie
        };

        const response = await updateStatusWstepnie(id, payload);
        alert(response.message); // Pokaż wiadomość użytkownikowi
        navigate(
          `/detail/${encodeURIComponent(nrRef)}/${encodeURIComponent(id)}`
        );
      } catch (error) {
        console.error("Failed to update status:", error);
        alert("Wystąpił błąd podczas aktualizacji statusu!");
      }
    }
  };

  const saveData = async () => {
    const processFieldValues = (fields) =>
      Object.fromEntries(
        Object.entries(fields).map(([key, value]) => [
          key,
          {
            value: value.value === "0" ? "" : value.value,
            verifyingPerson: value.verifyingPerson,
          },
        ])
      );

    const payload = {
      textFields: processFieldValues(data.textFields),
      selectFields: processFieldValues(data.selectFields),
      checkboxes: Object.fromEntries(
        Object.entries(data.checkboxesPreparation).map(([key, value]) => [
          key,
          {
            verifying: value.verifying,
            verifyingPerson: value.verifyingPerson,
          },
        ])
      ),
      nrRef,
      osobaPrzygotowujaca,
      osobaWeryfikujaca,
      comment: data.comment,
    };
    try {
      const response = await saveOrUpdateData(id, payload);
      alert(response.message);

      await verifyAndSubmit(data, status, id, username);

      await fetchAndSetData();

      if (wstepniePrzygotowane !== 1) {
        await updateStatusToPrePrepared();
      }
    } catch (error) {
      console.error("Failed to save or update data:", error);
      alert("Wystąpił błąd podczas zapisywania danych!");
    }
  };

  const saveInternalControlData = async () => {
    try {
      const allCheckboxesHaveValue = Object.entries(
        data.checkboxesInternalControl
      ).every(
        ([key, checkbox]) =>
          key === "14" || checkbox.good === true || checkbox.bad === true
      );

      if (!allCheckboxesHaveValue) {
        alert(
          "Wszystkie pola muszą być uzupełnione (good lub bad) przed zapisaniem danych!"
        );
        return;
      }

      // Dodanie numeru referencyjnego
      const payload = {
        checkboxesInternalControl: data.checkboxesInternalControl,
        nrRef: nrRef,
        osobaWeryfikujacaOdpraw: osobaWeryfikujaca,
        osobaPrzygotowujacaOdprawe: osobaPrzygotowujaca,
      };

      const response = await saveOrUpdateData(id, payload);

      // Obsługa komentarza
      if (data.comment) {
        const commentPayload = {
          comment: data.comment,
          username: username,
        };
        await saveComment(id, commentPayload);
      }

      const secondPayload = {
        nrRef: nrRef,
        osoba_kontrolujaca: username,
        data_kontroli: format(
          utcToZonedTime(new Date(), "Europe/Warsaw"),
          "yyyy-MM-dd'T'HH:mm:ssXXX"
        ),
      };

      await saveInternalControlDataDates(id, secondPayload);

      alert(response.message);
      window.location.reload();
    } catch (error) {
      console.error(
        "Failed to save internal control data or comment:",
        error.message
      );
      alert(
        "Wystąpił błąd podczas zapisywania danych kontroli wewnętrznej lub komentarza!"
      );
    }
  };

  const saveVerifyingData = async () => {
    if (status === "Do wysłania") {
      return;
    }

    const allCheckboxesHaveValue = Object.values(
      data.checkboxesVerification
    ).every((checkbox) => checkbox.good === true || checkbox.bad === true);

    if (!allCheckboxesHaveValue) {
      const payload = {
        checkboxes: data.checkboxesVerification,
        nrRef: nrRef,
        osobaWeryfikujaca: osobaWeryfikujaca,
        comment: data.comment,
      };

      try {
        const response = await saveVerifyingData1(id, payload);
        alert(response.message);
      } catch (error) {
        console.error("Failed to save verifying data:", error);
        alert("Wystąpił błąd podczas zapisywania danych weryfikujących!");
      }
      return;
    }

    const allCheckboxesVerified = Object.values(
      data.checkboxesVerification
    ).every((checkbox) => checkbox.good === true);

    if (allCheckboxesVerified) {
      const payload = {
        checkboxes: data.checkboxesVerification,
        nrRef: nrRef,
        osobaWeryfikujaca: osobaWeryfikujaca,
        comment: data.comment,
      };

      try {
        const response = await saveVerifyingData1(id, payload);
        alert(response.message);

        const statusPayload = {
          status: "Do wysłania",
          Zweryfikowane: 1,
        };

        await updateStatusDoWyslania(id, statusPayload, "przywoz");
        setTimeout(() => {
          navigate(
            `/detail/${encodeURIComponent(nrRef)}/${encodeURIComponent(id)}`
          );
        }, 1500);
      } catch (error) {
        console.error("Failed to save verifying data or update status:", error);
        alert(
          "Wystąpił błąd podczas zapisywania danych weryfikujących lub aktualizacji statusu!"
        );
      }
    } else {
      if (!data.comment || data.comment.length === 0) {
        alert(
          "Wystąpił błąd podczas zapisywania danych, komentarz nie może być pusty!"
        );
        return;
      }

      try {
        const payload = {
          checkboxes: data.checkboxesVerification,
          nrRef: nrRef,
          osobaWeryfikujaca: osobaWeryfikujaca,
          comment: data.comment,
        };

        const response = await saveVerifyingData1(id, payload);
        alert(response.message);

        const commentPayload = {
          comment: data.comment,
          username: osobaWeryfikujaca,
        };
        const statusPayload = {
          status: "Do poprawy",
          Zweryfikowane: 0,
        };

        await saveComment(id, commentPayload);
        await updateStatusDoPoprawy(id, statusPayload, "przywoz");
        await saveCheckbox(id, { zglosBladBox: 1 });
        setTimeout(() => {
          navigate(
            `/detail/${encodeURIComponent(nrRef)}/${encodeURIComponent(id)}`
          );
        }, 1500);
      } catch (error) {
        console.error(
          "Failed to save verifying data, comment, or update status:",
          error
        );
        alert(
          "Wystąpił błąd podczas zapisywania danych weryfikujących, komentarza lub aktualizacji statusu!"
        );
      }
    }
  };

  if (
    loading ||
    wstepniePrzygotowane === undefined ||
    !status ||
    !username ||
    privileges === undefined ||
    !privileges
  ) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
        <div>Pobieranie danych...</div>
      </div>
    );
  }

  const isFieldDisabled = (rowId) => {
    const isStatusValid =
      status === "Do przygotowania" || status === "Wstępnie przygotowane";
    const isWithinBlockedRange =
      wstepniePrzygotowane === 1 && rowId >= 1 && rowId <= 14;
    const hasUserPermission =
      username === osobaPrzygotowujaca || username === osobaWysylajaca;
    const isAllDataFilled = przygotowane === 1 && rowId >= 1 && rowId <= 32;

    const isRow33Blocked =
      rowId === 33 && (status === "Zrealizowane" || isAllDataFilled);

    return (
      (!isStatusValid && rowId !== 33) ||
      isWithinBlockedRange ||
      !hasUserPermission ||
      isAllDataFilled ||
      isRow33Blocked
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Nr. Ref: {nrRef}, Rodzaj odprawy: {rodzajOdprawy}, Status: {status},
            Osoba przygotowująca: {osobaPrzygotowujaca}, Osoba weryfikująca:{" "}
            {osobaWeryfikujaca}, Osoba wysyłająca: {osobaWysylajaca}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <List>
          <ListItem
            button
            onClick={() =>
              navigate(
                `/detail/${encodeURIComponent(nrRef)}/${encodeURIComponent(id)}`
              )
            }
          >
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={() => navigate("/login")}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableHeadCell}>
                  Sprawdź
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  Osoba przygotowująca
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  Osoba wprowadzająca
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeadCell}
                  colSpan={2}
                >
                  Osoba weryfikująca
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHeadCell}
                  colSpan={2}
                >
                  Kontrola wewnętrzna
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableHeadCell}></TableCell>
                <TableCell className={classes.tableHeadCell}></TableCell>
                <TableCell className={classes.tableHeadCell}></TableCell>
                <TableCell align="center" className={classes.tableSubHeadCell}>
                  Poprawnie
                </TableCell>
                <TableCell align="center" className={classes.tableSubHeadCell}>
                  Niepoprawnie
                </TableCell>
                <TableCell align="center" className={classes.tableSubHeadCell}>
                  Poprawnie
                </TableCell>
                <TableCell align="center" className={classes.tableSubHeadCell}>
                  Niepoprawnie
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={`${classes.tableRow} ${
                    row.id >= 15 && row.id <= 33 ? classes.greyRow : ""
                  }`}
                >
                  <TableCell className={classes.checkCell}>
                    {row.check}
                  </TableCell>
                  <TableCell className={classes.centered}>
                    {[21, 22, 26].includes(row.id) ? (
                      <TextField
                        className={classes.inputField}
                        value={data.textFields[row.id]?.value || ""}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "textFields",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        size="small"
                        disabled={isFieldDisabled(row.id)}
                      />
                    ) : [13, 19, 24, 27, 32, 33].includes(row.id) ? (
                      <Select
                        className={classes.inputField}
                        value={data.selectFields[row.id]?.value || ""}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "selectFields",
                            e.target.value
                          )
                        }
                        displayEmpty
                        disabled={isFieldDisabled(row.id)}
                      >
                        {selectOptions[row.id].map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Checkbox
                        checked={
                          data.checkboxesPreparation[row.id]?.verifying || false
                        }
                        onChange={(e) => {
                          const newChecked = e.target.checked;
                          setData((prevData) => ({
                            ...prevData,
                            checkboxesPreparation: {
                              ...prevData.checkboxesPreparation,
                              [row.id]: {
                                verifying: newChecked,
                                verifyingPerson: newChecked ? username : "",
                              },
                            },
                          }));
                        }}
                        color="primary"
                        disabled={isFieldDisabled(row.id)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {data.textFields[row.id]?.verifyingPerson ||
                      data.selectFields[row.id]?.verifyingPerson ||
                      data.checkboxesPreparation[row.id]?.verifyingPerson}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={
                        data.checkboxesVerification[row.id]?.good || false
                      }
                      onChange={() => handleVerificationChange(row.id, "good")}
                      color="primary"
                      disabled={
                        username !== osobaWeryfikujaca ||
                        allVeryfyingDataFilled ||
                        allVerificationSet
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={
                        data.checkboxesVerification[row.id]?.bad || false
                      }
                      onChange={() => handleVerificationChange(row.id, "bad")}
                      color="secondary"
                      disabled={
                        username !== osobaWeryfikujaca || allVerificationSet
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={
                        data.checkboxesInternalControl[row.id]?.good || false
                      }
                      onChange={() =>
                        handleInternalControlChange(row.id, "good")
                      }
                      color="primary"
                      disabled={
                        privileges.internalControl !== 1 ||
                        isAllInternalControleSaved
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={
                        data.checkboxesInternalControl[row.id]?.bad || false
                      }
                      onChange={() =>
                        handleInternalControlChange(row.id, "bad")
                      }
                      color="secondary"
                      disabled={
                        privileges.internalControl !== 1 ||
                        isAllInternalControleSaved
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {username === osobaWeryfikujaca && (
          <>
            <TextField
              label="Napisz co było nie tak"
              multiline
              rows={4}
              value={data.comment}
              onChange={handleCommentChange}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={allVeryfyingDataFilled} // Dodanie allVerificationSet do warunku disabled
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={saveVerifyingData}
              style={{ marginTop: "20px" }}
              disabled={allVeryfyingDataFilled} // Dodanie allVerificationSet do warunku disabled
            >
              Zapisz dane weryfikacyjne
            </Button>
          </>
        )}
        {(username === osobaPrzygotowujaca || username === osobaWysylajaca) && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={saveData}
              style={{ marginTop: "20px" }}
              disabled={
                status === "Zrealizowane" ||
                (username !== osobaPrzygotowujaca &&
                  username !== osobaWysylajaca)
              }
            >
              Zapisz dane
            </Button>
          </>
        )}
        {privileges.internalControl === 1 && (
          <>
            <TextField
              label="Napisz jeśli chcesz komentarz do kontroli wewnętrznej"
              multiline
              rows={4}
              value={data.comment}
              onChange={handleCommentChangeInternalControl}
              variant="outlined"
              fullWidth
              margin="normal"
              disabled={isAllInternalControleSaved}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={saveInternalControlData}
              style={{ marginTop: "20px" }}
              disabled={isAllInternalControleSaved}
            >
              Zapisz dane kontroli wewnętrznej
            </Button>
          </>
        )}

        <div style={{ marginTop: "20px" }}>
          {formatDate(data.creationDate) !== "Invalid date" && (
            <Typography variant="body1">
              Data utworzenia listy: {formatDate(data.creationDate)}
            </Typography>
          )}
          {formatDate(data.lastUpdateDate) !== "Invalid date" && (
            <Typography variant="body1">
              Data ostatniej aktualizacji: {formatDate(data.lastUpdateDate)}
            </Typography>
          )}
          {formatDate(data.data_kontroli) !== "Invalid date" && (
            <Typography variant="body1">
              Data wykonania kontroli wewnętrznej:{" "}
              {formatDate(data.data_kontroli)} wykonanej przez użytkownika:{" "}
              {data.osoba_kotrolujaca}
            </Typography>
          )}
        </div>
      </main>
    </div>
  );
};
export default UniwersalListImport;
