import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";

//-----------------------------------------------------------MUI----------------------------------------------------
import {
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Select,
  MenuItem,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

registerLocale("pl", pl);
//-----------------------------------------------------------STYLE----------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  formContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2), // Add margin bottom to the form container
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    width: "90%",
  },
  select: {
    marginBottom: theme.spacing(2), // Add margin bottom to the Select component
  },
  selectText: {
    fontSize: "1.5rem", // Increase the font size of the Select text
    marginBottom: theme.spacing(2), // Add margin bottom to the Select text
  },
  tableHeadRow: {
    height: 64, // Set the height of the TableHead row
  },

  tableHeadCell: {
    fontSize: "1.2rem", // Set the font size of the table header cells
    fontWeight: "bold", // Make the font bold for the table header cells
  },
}));

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`, // Include the token here
};

const drawerWidth = "10%";
const BASE_URL = "https://wega-api.com";

const RejestrLists = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [newRecordName, setNewRecordName] = useState("");
  const [isAddingRecord, setIsAddingRecord] = useState(false);

  const [selectedValue, setSelectedValue] = useState(() => {
    return localStorage.getItem("selectedValue") || "";
  });

  // Update selected value and save it to localStorage
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    localStorage.setItem("selectedValue", value);
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
  };

  const handleConfirmDelete = async () => {
    if (!selectedRow) {
      return;
    }

    try {
      // Make an API DELETE request to delete the selected row by its ID
      await axios.delete(
        `${BASE_URL}/delete/${selectedValue}/${selectedRow.id}`,
        {
          headers: headers, // Include your headers here
        }
      );

      //setIsDeleting(false); // Reset the delete state
      setSelectedRow(null); // Reset the selected row
    } catch (error) {
      console.error("Error deleting row:", error);
      toast.error("Wystąpił błąd podczas usuwania.", {
        autoClose: 3000,
      });
    }
    toast.success(`Poprawnie usunięto ${selectedRow.name}!`, {
      autoClose: 3000,
    });
    window.location.reload();
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleNewRecordNameChange = (e) => {
    setNewRecordName(e.target.value);
  };

  const handleAddRecordClick = () => {
    setIsAddingRecord(true);
  };

  const handleCloseFormClick = () => {
    setNewRecordName("");
    setIsAddingRecord(false);
  };

  // Function to clear filter and navigate to /user
  const handleBackToTableClick = () => {
    localStorage.removeItem("selectedValue");
    setSelectedValue("");
    navigate("/rejestrWnioskow");
  };

  // Function to clear filter and navigate to /login
  const handleLogoutClick = () => {
    localStorage.clear(); // Czyszczenie localStorage
    navigate("/login");
  };

  const handleSaveRecordClick = async () => {
    try {
      if (
        selectedValue === "uzytkownicy" ||
        selectedValue === "lokalizacjaTransportow"
      ) {
        await axios.post(
          `${BASE_URL}/add-record/${selectedValue}`,
          {
            name: newRecordName,
          },
          {
            headers: headers,
          }
        );
      } else {
        // Remove leading whitespace (spaces and tabs) and convert newRecordName to uppercase
        const uppercaseName = newRecordName.trimStart().toUpperCase();
        // Make a POST request to your API with headers to save the new record
        await axios.post(
          `${BASE_URL}/add-record/${selectedValue}`,
          {
            name: uppercaseName,
          },
          {
            headers: headers, // Pass the headers in the options object
          }
        );
      }

      // Fetch updated data from the API and update the tableData state
      const response = await axios.get(`${BASE_URL}/list/${selectedValue}`, {
        headers: headers,
      });
      setTableData(response.data);

      // Reset states
      setNewRecordName("");
      setIsAddingRecord(false);

      toast.success("Poprawnie dodano nowy rekord!", {
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error adding new record:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/list/${selectedValue}`, {
          headers: headers,
        });
        setTableData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedValue) {
      fetchData();
    }

    // Retrieve selected value from localStorage when the component mounts
    const savedSelectedValue = localStorage.getItem("selectedValue");
    if (savedSelectedValue) {
      setSelectedValue(savedSelectedValue);
    }
  }, [selectedValue]);

  // Sort the table data alphabetically by name using localeCompare
  const sortedTableData = [...tableData].sort((a, b) => {
    return a.name.localeCompare(b.name, "pl", { sensitivity: "base" });
  });

  // Filter sorted table data based on search term
  const filteredTableData = sortedTableData.filter((row) => {
    return row.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={handleBackToTableClick}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do Rejestru wniosków" />
          </ListItem>
          <ListItem button onClick={handleLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Paper elevation={3} className={classes.formContainer}>
          <Typography className={classes.selectText}>Wybierz listę</Typography>
          <Select
            className={classes.select}
            label="Wybierz Liste"
            value={selectedValue}
            onChange={handleSelectChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="powodWpisania">Lista Powodów wpisania</MenuItem>
          </Select>
          {loading && <CircularProgress style={{ margin: "20px" }} />}{" "}
          {/* Loading indicator */}
          {!loading && tableData.length > 0 && (
            <>
              <TextField
                label="Wyszukaj po nazwie"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchTermChange}
                style={{ marginBottom: "20px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddRecordClick}
                style={{ marginBottom: "20px" }}
              >
                Dodaj nową pozycje do tabeli
              </Button>

              {isAddingRecord && (
                <>
                  <TextField
                    label="Wpisz nazwe"
                    variant="outlined"
                    fullWidth
                    value={newRecordName}
                    onChange={handleNewRecordNameChange}
                    style={{ marginBottom: "20px" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveRecordClick}
                  >
                    Zapisz
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleCloseFormClick}
                  >
                    Close
                  </Button>
                </>
              )}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell className={classes.tableHeadCell}>
                        Nr.
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Nazwa
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Usuń
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTableData.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {selectedRow && selectedRow.id === row.id ? (
                            <IconButton onClick={handleConfirmDelete}>
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleDeleteClick(row)}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      </main>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default RejestrLists;
