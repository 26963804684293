// UserService.js

const API_URL = "https://wega-api.com/admin/users";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const getToken = () => localStorage.getItem("token");

export const fetchUsers = async () => {
  try {
    const response = await fetch(API_URL, {
      method: "GET",
      headers: headers,
    });
    if (!response.ok) {
      throw new Error("Błąd sieci!");
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/${userId}`, {
      method: "DELETE",
      headers: headers,
    });
    if (!response.ok) {
      throw new Error("Błąd podczas usuwania użytkownika");
    }
    return response;
  } catch (error) {
    throw error;
  }
};

// Uniwersalna funkcja do zapisywania użytkownika (tworzenie i aktualizacja)
export const saveUser = async (user, method = "POST", userId) => {
  const url = userId ? `${API_URL}/${userId}` : API_URL;
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(user),
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      errorText || "Nie udało się przeprowadzić operacji na użytkowniku."
    );
  }

  return await response.json(); // Zwraca odpowiedź z serwera, np. zaktualizowany obiekt użytkownika
};

export const updateUserPassword = async (userId, newPassword) => {
  const url = `${API_URL}/${userId}/updatePassword`; // Załóżmy, że API oczekuje na ten endpoint do zmiany hasła
  const options = {
    method: "PUT", // Zakładając, że używamy metody PUT do aktualizacji hasła
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Zakładamy, że getToken() jest funkcją do pobierania aktualnego tokena
    },
    body: JSON.stringify({ password: newPassword }), // Przekazujemy nowe hasło w ciele żądania
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      errorText || "Nie udało się zaktualizować hasła użytkownika."
    );
  }

  return await response.json(); // Zwraca odpowiedź z serwera, np. potwierdzenie zmiany hasła
};

export const updateAccessDeniedDate = async (userId) => {
  const url = `${API_URL}/${userId}/access_denied`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Zakładam, że getToken() jest funkcją do pobierania aktualnego tokena
    },
    body: JSON.stringify({ access_denied: new Date().toISOString() }), // Przekazujemy aktualną datę
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      errorText || "Nie udało się zaktualizować danych użytkownika."
    );
  }

  return await response.json();
};

export const updateAccessGiveDate = async (userId) => {
  const url = `${API_URL}/${userId}/access_added`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Zakładam, że getToken() jest funkcją do pobierania aktualnego tokena
    },
    body: JSON.stringify({
      date_added: new Date().toISOString(),
      access_denied: null,
    }), // Przekazujemy aktualną datę
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      errorText || "Nie udało się zaktualizować danych użytkownika."
    );
  }

  return await response.json();
};

export const updateUserPrivileges = async (userId, privileges) => {
  const url = `${API_URL}/${userId}/privileges`; // Upewnij się, że adres URL jest poprawny
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(privileges), // Przekazujemy sam obiekt `privileges`, bez opakowania
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    const errorText = await response.text();
    console.error("Błąd podczas wysyłania do API:", errorText);
    throw new Error(
      errorText || "Nie udało się zaktualizować przywilejów użytkownika."
    );
  }

  return await response.json();
};

// UserService.js
// fetchUserPrivileges.js
export const fetchUserPrivileges = async () => {
  const url = `${API_URL}/privileges`; // Upewnij się, że API odpowiada na tej ścieżce
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  const response = await fetch(url, options);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      errorText || "Nie udało się pobrać przywilejów użytkowników."
    );
  }

  return await response.json(); // Zwróć dane przywilejów wszystkich użytkowników
};
