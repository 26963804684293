import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Paper,
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { createWniosek } from "./RejestrFunctions"; // Import funkcji API
import { getExistingMRNs } from "./RejestrFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(10), // Dodaj margines od góry
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "1500px", // Jeszcze szersze
    margin: "0 auto", // Wyśrodkowanie
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  header: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
  },
  textField: {
    width: "100%", // Ustaw szerokość na 100% szerokości kontenera
    "& .MuiOutlinedInput-root": {
      height: "auto", // Automatyczna wysokość
    },
  },
}));

const NowyWniosek = () => {
  const classes = useStyles();
  const [wniosek, setWniosek] = useState("");
  const [existingMRNs, setExistingMRNs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExistingMRNs = async () => {
      try {
        const mrns = await getExistingMRNs();
        setExistingMRNs(mrns); // Ustaw istniejące MRN w stanie
      } catch (error) {
        console.err(error);
        toast.error("Błąd podczas pobierania numerów MRN.");
      }
    };

    fetchExistingMRNs();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const wnioskiArray = wniosek.split("\n").filter(Boolean); // Przekształcenie wniosku w tablicę
    const existingMRNsSet = new Set(existingMRNs); // Zestaw istniejących MRN dla szybszej walidacji

    const valuesToInsert = []; // Tablica do przechowywania wniosków do dodania
    const duplicates = []; // Tablica do przechowywania MRN duplikatów

    // Sprawdzenie, czy któryś z MRN już istnieje
    for (const wniosek of wnioskiArray) {
      const wniosekValues = wniosek.split("\t");
      const mrn = wniosekValues[1]; // Zakładam, że MRN jest na pozycji 1
      const isDuplicate = existingMRNsSet.has(mrn); // Ustal, czy jest duplikat

      if (isDuplicate) {
        duplicates.push(mrn); // Dodaj MRN do tablicy duplikatów
      }

      // Generowanie unique_number
      const fullName = wniosekValues[5].toUpperCase(); // "Wojtaś Milena"
      const initials = fullName.split(" "); // Rozdziel na części
      const firstInitial = initials[0][0]; // Pierwsza litera pierwszego imienia
      const secondInitial = initials.length > 1 ? initials[1][0] : ""; // Pierwsza litera drugiego imienia (jeśli istnieje)

      const platnik = wniosekValues[8] ? wniosekValues[8].toUpperCase() : ""; // Bezpieczne przypisanie
      const powod_Wpisania = wniosekValues[9]
        ? wniosekValues[9].toUpperCase()
        : ""; // Bezpieczne przypisanie

      const uniqueNumber = `${wniosekValues[7]}.${mrn}.${firstInitial}${secondInitial}`; // Tworzenie unique_number
      const sciezkaDoPliku = wniosekValues[10] ? wniosekValues[10] : ""; // Bezpieczne przypisanie

      // Przygotowanie danych do zapisania
      valuesToInsert.push([
        wniosekValues[0], // data_SAD
        mrn, // nr_MRN
        wniosekValues[2], // nr_akt
        wniosekValues[3], // nadawca_odbiorca
        wniosekValues[4], // odbiorca
        fullName, // podpisany
        wniosekValues[6], // rodzaj_SAD
        wniosekValues[7], // procedura
        platnik, // platnik (wielkie litery)
        powod_Wpisania, // powod_Wpisania (wielkie litery)
        sciezkaDoPliku,
        moment.tz("Europe/Warsaw").format("YYYY-MM-DD HH:mm:ss"), // data_wpisania
        uniqueNumber, // unique_number
        "W toku", // status
        isDuplicate ? 1 : 0, // duplikat
      ]);
    }

    // Zapisanie wniosków do bazy
    try {
      await createWniosek(valuesToInsert);

      if (duplicates.length > 0) {
        toast.warning(
          `Uwaga! Następujące numery MRN są duplikatami: ${duplicates.join(
            ", "
          )}`,
          { autoClose: 5000 } // Czas trwania powiadomienia
        );
      }

      toast.success("Wnioski zapisane pomyślnie!", { autoClose: 5000 }); // Czas trwania powiadomienia
      setTimeout(() => {
        navigate("/rejestrWnioskow"); // Przekierowanie po utworzeniu wniosku
      }, 5000);
    } catch (error) {
      console.err(error);
      toast.error("Błąd podczas tworzenia wniosku.", { autoClose: 5000 }); // Czas trwania powiadomienia
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const newValue = `${wniosek.substring(
        0,
        cursorPosition
      )}\t${wniosek.substring(cursorPosition)}`;
      setWniosek(newValue);
      setTimeout(
        () =>
          e.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1),
        0
      );
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Nowy Wniosek
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" className={classes.header}>
            Nowy wniosek
          </Typography>
          <Typography
            component="p"
            variant="body1"
            className={classes.instructions}
          >
            Aby złożyć nowy wniosek, wprowadź szczegóły w poniższym polu
            tekstowym. Możesz wpisać wartości rozdzielając tabulatorem
            (przyciskt "tab" na klawiaturze). <br /> Dane powinny być w
            kolejności:
            <br />- Data SAD* (format. YYYY-MM-DD), <br />- Numer MRN*, <br />-
            Nr. akt*, <br />- Nadawca*, <br />- Odbiorca*, <br />- Podpisany*
            (Nazwisko Imie), <br />- Rodzaj SAD*, <br />- Procedura*, <br />-
            Płatnik, <br />- Powód wpisania, <br />- Ścieżka do pliku
            <br />
            <br />* - Pola wymagane
            <br />
            Jeśli chcemy dodać wiele wniosków na raz jest to możliwe poprzez
            oddzielenie danych Enterem, czyli wpisujemy wszystkie dane do
            jednego wniosku następnie przycisk enterm i wprowadzamy dane dla
            drugiego wniosku.
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="wniosek"
              label="Wprowadź dane wniosku"
              name="wniosek"
              autoComplete="wniosek"
              autoFocus
              value={wniosek}
              onChange={(e) => setWniosek(e.target.value)}
              multiline
              rows={15} // Ustawienie wysokości na 15 wierszy
              className={classes.textField}
              onKeyDown={handleKeyDown} // Dodanie obsługi klawisza Tab
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Utwórz
            </Button>
          </form>
        </Paper>
      </Container>
      <ToastContainer position="top-right" autoClose={1500} />
    </div>
  );
};

export default NowyWniosek;
