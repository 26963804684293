import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Typography,
  Paper,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  TextField, // Import TextField
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
} from "@material-ui/core";
import {
  ExitToApp as ExitToAppIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import axios from "axios";

registerLocale("pl", pl);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  formContainer: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(1),
    width: "90%",
  },
  formTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "2.2rem",
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(2),
    fontSize: "1.2rem",
  },

  historyItem: {
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    borderRadius: theme.spacing(1),
  },
  marginTop: {
    marginTop: "30px",
  },
  marginBottom: {
    marginBottom: "30px", // Add margin bottom
  },
  // Add a class for the container that holds each set of field-value pairs
  fieldValuePairContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // Add a class for the field name (Pole, Stara wartość, etc.)
  fieldName: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    display: "flex",
  },
  // Add a class for the field value
  fieldValue: {
    marginLeft: theme.spacing(1), // Add some space between field name and value
  },
}));

const drawerWidth = "10%";
const BASE_URL = "https://wega-api.com";

const History = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Data, setHistoryData] = useState([]);
  const [filterValue, setFilterValue] = useState(""); // State variable for filter input
  const [loading, setLoading] = useState(true); // State variable for loading indicator
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    try {
      const [historyResponse, nrRefResponse] = await Promise.all([
        axios.get(`${BASE_URL}/history`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }),
        axios.get(`${BASE_URL}/nrRef`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }),
      ]);

      const historyData = historyResponse.data;
      const nrRefData = nrRefResponse.data;

      // Match history items with corresponding nrRef values based on customId
      const combinedData = historyData.map((historyItem) => {
        const matchingNrRef = nrRefData.find(
          (nrRef) => nrRef.id === historyItem.customId
        );
        return {
          customId: historyItem.customId,
          nrRef: matchingNrRef ? matchingNrRef.nrRef : "", // Set nrRef if found, otherwise empty string
          fieldName: historyItem.fieldName,
          oldValue: historyItem.oldValue,
          newValue: historyItem.newValue,
          editedBy: historyItem.editedBy,
          editedAt: historyItem.editedAt,
        };
      });

      setHistoryData(combinedData);

      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching combined data:", error);
      setHistoryData([]);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Inside the component
  const formatDate = (dateTimeString) => {
    // Parse the original date time string using moment
    const originalDateTime = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSSZ");

    // Format the date in the desired format
    return originalDateTime.format("HH:mm, DD-MM-YYYY");
  };
  // Filter historyData based on the filterValue (nrRef)
  const filteredHistoryData = filterValue
    ? Data.filter(
        (item) =>
          item.nrRef.trim().toLowerCase() === filterValue.trim().toLowerCase()
      )
    : Data;

  const handleLogout = () => {
    localStorage.clear(); // Czyszczenie localStorage
    navigate("/login"); // Nawigacja na stronę /login
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <List>
          <ListItem button onClick={() => navigate("/user")}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary="Powrót do tabeli" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Paper elevation={3} className={classes.formContainer}>
          <Typography variant="h1" className={classes.formTitle}>
            Historia zmian w zgłoszeniach
          </Typography>

          {loading ? ( // Display loading indicator if loading is true
            <div className={classes.marginBottom}>
              <Typography variant="h6">Loading...</Typography>
              <CircularProgress />
            </div>
          ) : (
            <>
              {/* Filter input field */}
              <div className={classes.marginBottom}>
                <TextField
                  label="Filtruj nrRef"
                  variant="outlined"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </div>

              <TableContainer
                component={Paper}
                className={classes.marginBottom}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">Nr. Ref</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Pole</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Stara wartość</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Nowa wartość</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Edytowana przez</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Edytowana dnia</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredHistoryData && filteredHistoryData.length > 0 ? (
                      filteredHistoryData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.customId}>
                            <TableCell>{item.nrRef}</TableCell>
                            <TableCell>{item.fieldName}</TableCell>
                            <TableCell>{item.oldValue}</TableCell>
                            <TableCell>{item.newValue}</TableCell>
                            <TableCell>{item.editedBy}</TableCell>
                            <TableCell>{formatDate(item.editedAt)}</TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Table Pagination */}
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={Data ? Data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </main>
    </div>
  );
};

export default History;
