import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
} from "@material-ui/core";
import { updateAccessDeniedDate, updateAccessGiveDate } from "./UserService";

const UserEditModal = ({ user, open, onClose, onSave }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  useEffect(() => {
    // Zaktualizuj stan przy każdej zmianie przekazywanego użytkownika
    setEditedUser({ ...user });
  }, [user]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    const prevStateValue = editedUser[name]; // Zapisz poprzednią wartość

    setEditedUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Sprawdź, czy wartość 'access' została zmieniona z 1 na 0
    if (name === "access" && prevStateValue === 1 && value === 0) {
      try {
        await updateAccessDeniedDate(editedUser.id); // Przekazuje id użytkownika
        console.log("Access denied date updated successfully.");
      } catch (error) {
        console.error("Failed to update access denied date.", error);
      }
    }
    if (name === "access" && prevStateValue === 0 && value === 1) {
      try {
        await updateAccessGiveDate(editedUser.id); // Przekazuje id użytkownika
        console.log("Access added date updated successfully.");
      } catch (error) {
        console.error("Failed to update access denied date.", error);
      }
    }
  };

  // Przykładowe role, które można dostosować do twoich potrzeb
  const roles = ["admin", "menager", "agent"];
  const accesses = [1, 0];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edytuj użytkownika</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="username"
          label="Nazwa użytkownika"
          type="text"
          fullWidth
          value={editedUser.username || ""}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={editedUser.email || ""}
          onChange={handleChange}
        />
        {/* Select dla roli */}
        <TextField
          select
          margin="dense"
          name="role"
          label="Rola"
          fullWidth
          value={editedUser.role || ""}
          onChange={handleChange}
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          margin="dense"
          name="access"
          label="Access"
          fullWidth
          value={editedUser.access}
          onChange={handleChange}
        >
          {accesses.map((access) => (
            <MenuItem key={access} value={access}>
              {access}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={() => onSave(editedUser)} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditModal;
